(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
    typeof define === 'function' && define.amd ? define('uikiticons', factory) :
    (global = global || self, global.UIkitIcons = factory());
}(this, (function () { 'use strict';

    function plugin(UIkit) {

        if (plugin.installed) {
            return;
        }

        UIkit.icon.add({
            'add-comment': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 5C22 3.9 21.1 3 20 3H4C2.9 3 2 3.9 2 5V17C2 18.1 2.9 19 4 19H18L20.4 20.8C21.0592 21.2944 22 20.824 22 20V5ZM20 18.17L18.83 17H4V5H20V18.17ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V10H8C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12H11V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V12H16C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10H13V7Z"/></svg>',

            'add': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H11V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H13V8ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"/></svg>',

            'apply-to-all': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="8.5" y="4.5" width="11" height="11" rx="1.5" fill="none" stroke="#000"/><rect x="4.5" y="8.5" width="11" height="11" rx="1.5" fill="none" stroke="#000"/></svg>',

            'arrow-left': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.7062 8.11538C15.095 7.72569 15.0947 7.09466 14.7054 6.70538C14.3158 6.31581 13.6842 6.31581 13.2946 6.70538L8.70711 11.2929C8.31658 11.6834 8.31658 12.3166 8.70711 12.7071L13.2946 17.2946C13.6842 17.6842 14.3158 17.6842 14.7054 17.2946C15.0947 16.9053 15.095 16.2743 14.7062 15.8846L10.83 12L14.7062 8.11538Z" /></svg>',

            'arrow-right': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.7055 6.70538C10.3159 6.31581 9.68428 6.31581 9.2947 6.70538C8.90543 7.09466 8.90509 7.72569 9.29393 8.11538L13.1701 12L9.29393 15.8846C8.90509 16.2743 8.90543 16.9053 9.2947 17.2946C9.68428 17.6842 10.3159 17.6842 10.7055 17.2946L15.293 12.7071C15.6835 12.3166 15.6835 11.6834 15.293 11.2929L10.7055 6.70538Z"/></svg>',

            'basket': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.7545 2.42044L17 9L21.0909 8.98736C21.5909 8.98736 22 9.43783 22 9.98841L21.9727 10.2587L19.6636 19.5385C19.4455 20.3793 18.7455 21 17.9091 21H6.09091C5.25455 21 4.55455 20.3793 4.34545 19.5385L2.03636 10.2587C2.00909 10.1686 2 10.0785 2 9.98841C2 9.43783 2.40909 8.98736 2.90909 8.98736L7 9L11.2455 2.43045C11.4182 2.14015 11.7091 2 12 2C12.2909 2 12.5818 2.14015 12.7545 2.42044ZM12 3.98207L9 9H15L12 3.98207ZM11.992 16.9958C11.9947 16.9958 11.9973 16.9958 12 16.9958C12.0027 16.9958 12.0053 16.9958 12.008 16.9958H11.992Z"/></svg>',

            'bell': '<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0.207106 15.7929C0.0744982 15.9255 0 16.1054 0 16.2929C0 16.6834 0.316583 17 0.707107 17H15.2929C15.6834 17 16 16.6834 16 16.2929C16 16.1054 15.9255 15.9255 15.7929 15.7929L14 14Z"/></svg>',

            'calendar': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 2H12.5V1H11.5V2H4.5V1H3.5V2H2.5C1.76667 2 1.5 2.26667 1.5 3V14.3333C1.5 15.0667 2.1 15.3334 2.83333 15.3334H13.5C14.2333 15.3334 14.5 15.0667 14.5 14.3333V3C14.5 2.26667 14.2333 2 13.5 2ZM13.5 14.3333H2.5V5H13.5V14.3333Z"/></svg>',

            'checkbox-default': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H6zm0 1a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1H6z"/></svg>',

            'checkbox-filled': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6ZM10.9193 15.8553C10.7259 16.0524 10.4094 16.0555 10.2122 15.8621L10.2115 15.8614L7.35692 13.0608C7.1598 12.8674 7.15677 12.5509 7.35016 12.3537C7.54355 12.1566 7.86012 12.1536 8.05723 12.347L10.5556 14.7981L15.808 9.44436C16.0014 9.24725 16.318 9.24422 16.5151 9.43761C16.7122 9.631 16.7152 9.94756 16.5219 10.1447L10.9193 15.8553Z"/></svg>',

            'checkbox-point': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H6zm0 1a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1H6z"/><rect x="9" y="9" width="6" height="6" rx="3"/></svg>',

            'chevron-down': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.147 10.854a.5.5 0 01.707-.708l3.242 3.243a.5.5 0 01-.707.707l-3.242-3.242z"/><path d="M14.632 10.146a.5.5 0 01.707.708l-3.243 3.242a.5.5 0 11-.707-.707l3.243-3.243z"/></svg>',

            'chevron-left': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.146 7.661a.5.5 0 01.708.707l-3.243 3.243a.5.5 0 11-.707-.707l3.242-3.243z"/><path d="M13.854 14.146a.5.5 0 01-.708.708L9.904 11.61a.5.5 0 11.707-.707l3.243 3.242z"/></svg>',

            'chevron-right': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.854 15.339a.5.5 0 01-.708-.707l3.243-3.243a.5.5 0 11.707.707l-3.242 3.243z"/><path d="M10.146 8.854a.5.5 0 01.708-.708l3.242 3.243a.5.5 0 11-.707.707l-3.243-3.242z"/></svg>',

            'chevron-up': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.339 13.39a.5.5 0 11-.707.706l-3.243-3.242a.5.5 0 11.707-.707l3.243 3.242z"/><path d="M8.854 14.096a.5.5 0 11-.708-.707l3.243-3.242a.5.5 0 11.707.707l-3.242 3.242z"/></svg>',

            'close': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.295 7.115C18.6844 6.72564 18.6844 6.09436 18.295 5.705C17.9056 5.31564 17.2744 5.31564 16.885 5.705L12 10.59L7.115 5.705C6.72564 5.31564 6.09436 5.31564 5.705 5.705C5.31564 6.09436 5.31564 6.72564 5.705 7.115L10.59 12L5.705 16.885C5.31564 17.2744 5.31564 17.9056 5.705 18.295C6.09436 18.6844 6.72564 18.6844 7.115 18.295L12 13.41L16.885 18.295C17.2744 18.6844 17.9056 18.6844 18.295 18.295C18.6844 17.9056 18.6844 17.2744 18.295 16.885L13.41 12L18.295 7.115Z"/></svg>',

            'copy-link': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.1399 6.88237C17.577 6.3175 16.8123 6 16.0149 6C15.2174 6 14.4528 6.3175 13.8899 6.88237L14.5999 7.59237C15.1063 7.08594 15.8444 6.88816 16.5362 7.07352C17.228 7.25889 17.7684 7.79924 17.9537 8.49102C18.1391 9.18281 17.9413 9.92094 17.4349 10.4274L13.4349 14.4274C12.9293 14.9338 12.192 15.132 11.5007 14.9475C10.8094 14.7629 10.269 14.2235 10.0832 13.5325C9.89739 12.8414 10.0943 12.1038 10.5999 11.5974L11.3049 10.8874L10.5999 10.1774L9.88988 10.8874C9.32501 11.4503 9.00751 12.2149 9.00751 13.0124C9.00751 13.8098 9.32501 14.5745 9.88988 15.1374C10.4556 15.6959 11.2199 16.007 12.0149 16.0024C12.8155 16.0057 13.5841 15.6888 14.1499 15.1224L18.1499 11.1224C19.3169 9.94834 19.3125 8.05087 18.1399 6.88237ZM5.60988 15.9124C5.23279 15.537 5.0208 15.0269 5.0208 14.4949C5.0208 13.9628 5.23279 13.4527 5.60988 13.0774L9.60988 9.07737C9.98521 8.70028 10.4953 8.48828 11.0274 8.48828C11.5594 8.48828 12.0695 8.70028 12.4449 9.07737C12.8197 9.45578 13.0253 9.96984 13.0149 10.5024C13.018 11.0364 12.8073 11.5495 12.4299 11.9274L11.3699 13.0024L12.0799 13.7124L13.1399 12.6524C14.3149 11.4774 14.3149 9.57235 13.1399 8.39737C11.9649 7.22238 10.0599 7.22238 8.88488 8.39737L4.88488 12.3974C4.31848 12.9605 4 13.7262 4 14.5249C4 15.3235 4.31848 16.0893 4.88488 16.6524C5.45429 17.2066 6.22033 17.5123 7.01488 17.5024C7.81643 17.5031 8.58574 17.1868 9.15488 16.6224L8.44488 15.9124C8.06954 16.2895 7.55942 16.5014 7.02738 16.5014C6.49533 16.5014 5.98521 16.2895 5.60988 15.9124Z"/></svg>',

            'crop': '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M19.436 15.423h-2.484V9.046a2.12 2.194 0 00-2.12-2.195h-6.16V4.28a.828.857 0 00-1.655 0V6.85H4.533a.828.857 0 000 1.715h2.484v6.377a2.12 2.194 0 002.12 2.195h6.16v2.571a.828.857 0 001.655 0v-2.571h2.484a.828.857 0 000-1.715zm-10.3 0a.464.48 0 01-.463-.48V8.566h6.16a.464.48 0 01.463.48v6.377h-6.16z"/></svg>',

            'dashboard': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="12" width="11" height="11" rx="2" transform="rotate(-45 4 12)"/></svg>',

            'download': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 17.5C4 17.2239 4.22386 17 4.5 17C4.77614 17 5 17.2239 5 17.5V19H19V17.5C19 17.2239 19.2239 17 19.5 17C19.7761 17 20 17.2239 20 17.5V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V17.5Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12.5015 5.5C12.5015 5.22386 12.2777 5 12.0015 5C11.7254 5 11.5015 5.22386 11.5015 5.5V15.697L8.20766 12.0372C8.02293 11.8319 7.70678 11.8153 7.50153 12C7.29627 12.1847 7.27964 12.5009 7.46437 12.7061L11.6299 17.3345C11.8285 17.5552 12.1746 17.5552 12.3732 17.3345L16.5387 12.7061C16.7234 12.5009 16.7068 12.1847 16.5015 12C16.2963 11.8153 15.9801 11.8319 15.7954 12.0372L12.5015 15.697V5.5Z"/></svg>',

            'drop-down': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10L12 15L17 10H7Z"/></svg>',

            'drop-up': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 14L12 9L17 14H7Z"/></svg>',

            'full-screen': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M18.157 9.503V5.71l-4.303 4.303a.5.5 0 01-.708-.707l4.3-4.3-3.79.022a.5.5 0 01-.005-1l5-.028c.14 0 .267.056.358.148.091.09.148.216.148.355v5a.5.5 0 01-1 0zM5 13.66v3.793l4.303-4.304a.5.5 0 11.707.707l-4.299 4.3 3.789-.022a.5.5 0 11.006 1l-5 .029a.498.498 0 01-.358-.148A.499.499 0 014 18.66v-5a.5.5 0 011 0z"/></svg>',

            'file-list': '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect fill="none" stroke="#000" width="16.25" height="21.25" x="4.125" y="1.5" stroke-width="6%" rx="1"></rect><line fill="none" stroke="#000" x1="7.5" x2="17.5" y1="15.625" y2="15.625"></line><line fill="none" stroke="#000" x1="7.5" x2="17.5" y1="10.625" y2="10.625"></line><line fill="none" stroke="#000" x1="7.5" x2="17.5" y1="8.125" y2="8.125"></line><line fill="none" stroke="#000" x1="7.5" x2="17.5" y1="13.125" y2="13.125"></line></svg>',

            'google-drive': '<img src="' + window.baseUrl + 'images/google-drive.png"/>',

            'hide': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="#000" d="M6.646 17.96L17.253 7.353"/><path d="M12 17c-1.705 0-3.217-.542-4.539-1.346-1.292-.785-2.398-1.816-3.332-2.829a.475.475 0 01.003-.653c1.03-1.116 2.387-2.257 3.884-3.059C9.276 8.44 10.639 8 11.999 8c1.363 0 2.726.44 3.987 1.113 1.494.805 2.854 1.945 3.884 3.062a.475.475 0 010 .653c-.934 1.01-2.04 2.044-3.33 2.826C15.218 16.458 13.706 17 12 17zm0-7.099c1.531 0 2.774 1.163 2.774 2.599 0 1.436-1.243 2.601-2.775 2.601-1.532 0-2.772-1.165-2.772-2.601s1.24-2.599 2.772-2.599zM8.04 14.82C9.2 15.525 10.52 16 12 16c1.479 0 2.798-.476 3.96-1.181 1.056-.643 1.987-1.475 2.798-2.317-.913-.933-2.057-1.855-3.303-2.522-1.11-.594-2.294-.98-3.456-.98-1.158 0-2.343.386-3.452.98-1.246.667-2.39 1.589-3.303 2.522.808.842 1.742 1.674 2.795 2.317z"/></svg>',
            
            'image-placeholder': '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M43.5 0H4.5C2.01469 0 0 2.01469 0 4.5V43.5C0 45.9853 2.01469 48 4.5 48H43.5C45.9853 48 48 45.9853 48 43.5V4.5C48 2.01469 45.9853 0 43.5 0ZM45 43.5C45 44.3285 44.3285 45 43.5 45H4.5C3.67153 45 3 44.3285 3 43.5V4.5C3 3.67153 3.67153 3 4.5 3H43.5C44.3285 3 45 3.67153 45 4.5V43.5Z"/><path d="M32.565 25.9349C31.98 25.3533 31.0351 25.3533 30.45 25.9349L27 29.3849L20.5651 22.9499C19.98 22.3683 19.0351 22.3683 18.4501 22.9499L7.95005 33.4499C7.37011 34.0415 7.37939 34.9911 7.97096 35.5711C8.24611 35.8409 8.61464 35.9945 9.00005 35.9999H39C39.8284 36.0047 40.5039 35.3371 40.5088 34.5086C40.5111 34.1056 40.3512 33.7187 40.065 33.4349L32.565 25.9349Z"/><path d="M28.5 21C30.9853 21 33 18.9853 33 16.5C33 14.0147 30.9853 12 28.5 12C26.0147 12 24 14.0147 24 16.5C24 18.9853 26.0147 21 28.5 21Z"/></svg>',

            'info': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="7.5" y="6.5" width="1" height="5" rx="0.5" fill="#000"/><rect x="7.5" y="4.5" width="1" height="1" rx="0.5" fill="#000"/><circle cx="8" cy="8" r="7.5" stroke="#000" fill="none"/></svg>',

            'info-filled': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#000"/><rect x="7.5" y="6.5" width="1" height="5" rx="0.5" fill="white"/><rect x="7.5" y="4.5" width="1" height="1" rx="0.5" fill="white"/></svg>',

            'locked-size': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.272 4.042a4.294 4.294 0 00-1.263 3.04l-.003.002v3.933c0 .217.018.432.05.64a4.716 4.716 0 012.27-2.29l-.003-2.283.003-.003a1.99 1.99 0 01.587-1.398 1.971 1.971 0 011.398-.587 1.97 1.97 0 011.401.584c.363.364.591.862.59 1.401l-.003.003v3.933l.003.003c0 .538-.228 1.033-.59 1.395a1.976 1.976 0 01-2.618.166c-.109.128-.2.272-.264.424a1.618 1.618 0 00-.117.492v1.518a4.292 4.292 0 004.64-.959 4.289 4.289 0 001.26-3.042h.006V7.081h-.006a4.299 4.299 0 00-1.26-3.042 4.289 4.289 0 00-3.042-1.266 4.27 4.27 0 00-3.04 1.27zm-.003 6.524a4.286 4.286 0 00-1.263 3.039l-.003.003v3.933c0 1.186.486 2.261 1.266 3.042a4.293 4.293 0 006.087.003 4.299 4.299 0 001.26-3.042l.003-.003-.003-3.93a4.056 4.056 0 00-.046-.636 4.692 4.692 0 01-2.228 2.264l-.04.022-.006 2.28.003.003c0 .54-.225 1.036-.587 1.398a1.98 1.98 0 01-1.401.584 1.98 1.98 0 01-1.402-.584 1.97 1.97 0 01-.583-1.401l-.003-3.93.003-.003a1.984 1.984 0 01.586-1.398c.36-.36.854-.587 1.399-.587.457-.002.883.156 1.22.42.106-.125.196-.274.26-.426.067-.155.106-.324.117-.498V9.607a4.306 4.306 0 00-4.64.959z"/></svg>',
            
            'message-error': '<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#000"/><rect x="4.2" y="11.171" width="10" height="1" rx=".5" transform="rotate(-45 4.2 11.171)"fill="white"/><rect x="4.907" y="4.1" width="10" height="1" rx=".5" transform="rotate(45 4.907 4.1)" fill="white"/></svg>',
            
            'message-info-filled': '<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#000"/><rect x="7.5" y="6.5" width="1" height="5" rx=".5" fill="white"/><rect x="7.5" y="4.5" width="1" height="1" rx=".5" fill="white"/></svg>',
            
            'message-info-outline': '<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="7.5" y="6.5" width="1" height="5" rx=".5"/><rect x="7.5" y="4.5" width="1" height="1" rx=".5"/><circle cx="8" cy="8" r="7.5" fill="none" stroke="#000"/></svg>',
            
            'message-succcess': '<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#000"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6.542 10.872c.167.173.434.17.598-.006l4.74-5.106a.465.465 0 00-.007-.632.407.407 0 00-.598.006L6.832 9.921 4.72 7.729a.407.407 0 00-.598.006.465.465 0 00.006.633l2.407 2.496a.704.704 0 00.008.008z" fill="white"/></svg>',
            
            'message-warning': '<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#000"/><path d="M7.1 4.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0L7.1 4.995z" fill="white"/><rect x="7.5" y="10.5" width="1" height="1" rx=".5" fill="white"/></svg>',
            
            'more-actions': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"/></svg>',
            
            'notification-asset-status': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill-opacity="0.2"/><rect x="5.5" y="5.5" width="13" height="13" rx="1.5" fill="none" stroke="#000"/><rect x="8" y="8" width="4" height="4" rx="1"/><rect x="13" y="8" width="3" height="1" rx=".5"/><rect x="13" y="10" width="3" height="1" rx=".5"/><rect x="8" y="13" width="8" height="1" rx=".5"/><rect x="8" y="15" width="8" height="1" rx=".5"/></svg>',
            
            'notification-downloading': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill-opacity="0.2"/><path clip-rule="evenodd" d="M9 15v-.5a.5.5 0 00-1 0v.5a1 1 0 001 1h6a1 1 0 001-1v-.5a.5.5 0 00-1 0v.5H9z"/><rect x="5.5" y="5.5" width="13" height="13" rx="1.5" fill="none" stroke="#000"/><rect x="11.5" y="8" width="1" height="6" rx=".5"/><path clip-rule="evenodd" d="M10.94 13.646l-.94-.939a.5.5 0 01.707-.707l.94.94a.5.5 0 00.707 0l.939-.94a.5.5 0 01.707.707l-.94.94a1.5 1.5 0 01-2.12 0z"/></svg>',
            
            'notification-expired': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill-opacity="0.2"/><rect x="5.5" y="5.5" width="13" height="13" rx="1.5" fill="none" stroke="#000"/><circle cx="12" cy="15" r="1"/><path d="M11.1 8.995a.905.905 0 111.8 0l-.35 3.508a.553.553 0 01-1.1 0l-.35-3.508z"/></svg>',
            
            'notification-comment': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill-opacity="0.2"/><rect x="5.5" y="5.5" width="13" height="13" rx="1.5" fill="none" stroke="#000"/><path clip-rule="evenodd" fill-rule="evenodd" d="M15 9H9v5h6V9zM9 8a1 1 0 00-1 1v5a1 1 0 001 1h6c0 .5 0 1 1 1V9a1 1 0 00-1-1H9z"/><rect x="10" y="10" width="4" height="1" rx=".5"/><rect x="10" y="12" width="4" height="1" rx=".5"/></svg>',
            
            'notification-sharing': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill-opacity="0.2"/><rect x="5.5" y="5.5" width="13" height="13" rx="1.5" fill="none" stroke="#000"/><path clip-rule="evenodd" d="M9.998 12.065A.987.987 0 0010 12l3.696-2.134a1 1 0 10-.5-.866L9.5 11.134a1 1 0 10-.06 1.764l3.724 2.114a1 1 0 10.494-.87l-3.66-2.077z"/></svg>',

            'notification-upload': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="-0.5" width="13" height="13" rx="1.5" transform="matrix(1 0 0 -1 5 18)" fill="none" stroke="#55B0EE"/> <rect width="1" height="6" rx="0.5" transform="matrix(1 0 0 -1 11.5 14.0859)" fill="#55B0EE"/><path clip-rule="evenodd" d="M10.9394 8.43945L10 9.37879C9.80478 9.57406 9.80478 9.89064 10 10.0859V10.0859C10.1953 10.2812 10.5119 10.2812 10.7071 10.0859L11.6465 9.14656C11.8417 8.9513 12.1583 8.9513 12.3536 9.14656L13.2929 10.0859C13.4882 10.2812 13.8048 10.2812 14 10.0859V10.0859C14.1953 9.89064 14.1953 9.57406 14 9.37879L13.0607 8.43945C12.4749 7.85367 11.5252 7.85367 10.9394 8.43945Z" fill="#55B0EE"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M9 15V14.5C9 14.2239 8.77614 14 8.5 14C8.22386 14 8 14.2239 8 14.5V15C8 15.5523 8.44772 16 9 16H15C15.5523 16 16 15.5523 16 15V14.5C16 14.2239 15.7761 14 15.5 14C15.2239 14 15 14.2239 15 14.5V15H9Z" fill="#55B0EE"/> <circle r="12" transform="matrix(1 0 0 -1 12 12)" fill="#55B0EE" fill-opacity="0.2"/> </svg>',

            'onedrive': '<img src="' + window.baseUrl + 'images/onedrive.png"/>',

            'open': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17Z"/></svg>',
            
            'radio-jackdow-active': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="8"/></svg>',
            
            'radio-jackdow-default': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 20a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12z"/></svg>',
            
            'radio-jackdow-filled': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path  fill-rule="evenodd" clip-rule="evenodd" d="M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-2 0a6 6 0 11-12 0 6 6 0 0112 0zm-9.742.916a.85.85 0 010-1.221.895.895 0 011.248 0l1.247 1.22 3.742-3.662a.895.895 0 011.247 0 .85.85 0 010 1.22l-4.366 4.274a.895.895 0 01-1.247 0l-1.87-1.831z"/></svg>',
            
            'radiobutton-default': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M12 20a8 8 0 100-16 8 8 0 000 16zm0-1a7 7 0 100-14 7 7 0 000 14z"/></svg>',
            
            'radiobutton-filled': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-1 0a7 7 0 11-14 0 7 7 0 0114 0zm-7 5a5 5 0 100-10 5 5 0 000 10z"/></svg>',
            
            'rating-checked': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.71613 10.2313L2.87145 15.4173C2.80412 15.8292 3.24346 16.1384 3.60679 15.9361L8.00016 13.5043L12.3935 15.9367C12.7535 16.137 13.1969 15.8339 13.1289 15.4179L12.2842 10.232L15.8575 6.56371C16.1409 6.27327 15.9782 5.78052 15.5755 5.71909L10.6568 4.9666L8.45216 0.263403C8.28749 -0.0878011 7.71215 -0.0878011 7.54749 0.263403L5.34347 4.96593L0.424769 5.71842C0.0207663 5.78052 -0.140568 6.2726 0.142767 6.56305L3.71613 10.2313Z"/></svg>',
            
            'rating-unchecked': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.71594 10.2323L2.87124 15.4184C2.84058 15.6074 2.91991 15.7977 3.07658 15.9085C3.23259 16.0187 3.43859 16.0307 3.6066 15.9372L8.00007 13.5054L12.3935 15.9379C12.4695 15.9793 12.5529 16 12.6355 16C12.7369 16 12.8376 15.9693 12.9236 15.9085C13.0802 15.7977 13.1596 15.6074 13.1289 15.4184L12.2842 10.2323L15.8576 6.56392C15.9883 6.42971 16.0337 6.23273 15.9743 6.05512C15.915 5.87684 15.761 5.74798 15.5756 5.71926L10.6568 4.96676L8.45208 0.263411C8.28741 -0.0878038 7.71206 -0.0878038 7.54739 0.263411L5.34332 4.96676L0.424502 5.71926C0.239163 5.74798 0.0851579 5.87684 0.0258227 6.05512C-0.0335125 6.2334 0.0111556 6.42971 0.141826 6.56392L3.71594 10.2323ZM5.758 5.91624C5.92267 5.89087 6.06401 5.78537 6.13468 5.63447L8.00007 1.65425L9.86546 5.6338C9.93613 5.7847 10.0775 5.89087 10.2421 5.91557L14.4629 6.56125L11.3928 9.71284C11.2815 9.82635 11.2315 9.9866 11.2568 10.1435L11.9769 14.5617L8.24208 12.4945C8.16674 12.4531 8.0834 12.4317 8.00007 12.4317C7.91673 12.4317 7.8334 12.4524 7.75806 12.4945L4.02328 14.5617L4.7433 10.1435C4.76864 9.9866 4.71863 9.82701 4.6073 9.71284L1.5372 6.56125L5.758 5.91624Z"/></svg>',
            
            'remove': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="6.70581" width="0.998052" height="15.9688" rx="0.499026" transform="rotate(-45 6 6.70581)"/><rect width="0.998051" height="15.9688" rx="0.499026" transform="matrix(0.706953 0.707261 -0.706953 0.707261 17.2893 6)"/></svg>',
            
            'rotate-left': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.11007 8.53L5.70007 7.11C4.80007 8.27 4.24007 9.61 4.07007 11H6.09007C6.23007 10.13 6.58007 9.28 7.11007 8.53ZM6.09007 13H4.07007C4.24007 14.39 4.79007 15.73 5.69007 16.89L7.10007 15.47C6.58007 14.72 6.23007 13.88 6.09007 13ZM7.10007 18.32C8.26007 19.22 9.61007 19.76 11.0001 19.93V17.9C10.1301 17.75 9.29007 17.41 8.54007 16.87L7.10007 18.32ZM13.0001 4.07V1L8.45007 5.55L13.0001 10V6.09C15.8401 6.57 18.0001 9.03 18.0001 12C18.0001 14.97 15.8401 17.43 13.0001 17.91V19.93C16.9501 19.44 20.0001 16.08 20.0001 12C20.0001 7.92 16.9501 4.56 13.0001 4.07Z"/></svg>',
            
            'rotate-right': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.55 5.55L11 1V4.07C7.06 4.56 4 7.92 4 12C4 16.08 7.05 19.44 11 19.93V17.91C8.16 17.43 6 14.97 6 12C6 9.03 8.16 6.57 11 6.09V10L15.55 5.55ZM19.93 11C19.76 9.61 19.21 8.27 18.31 7.11L16.89 8.53C17.43 9.28 17.77 10.13 17.91 11H19.93ZM13 17.9V19.92C14.39 19.75 15.74 19.21 16.9 18.31L15.46 16.87C14.71 17.41 13.87 17.76 13 17.9ZM16.89 15.48L18.31 16.89C19.21 15.73 19.76 14.39 19.93 13H17.91C17.77 13.87 17.43 14.72 16.89 15.48Z"/></svg>',
            
            'search': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.793 14.2936C15.6054 14.1056 15.3508 14 15.0852 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.0852C14 15.3508 14.1056 15.6054 14.2936 15.793L18.2929 19.7843C18.6835 20.1742 19.3162 20.1738 19.7064 19.7836L19.745 19.745L19.7836 19.7064C20.1738 19.3162 20.1742 18.6835 19.7843 18.2929L15.793 14.2936ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"/></svg>',
            
            'show-less': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.7071 8.70711C12.3166 8.31658 11.6834 8.31658 11.2929 8.70711L6.70538 13.2946C6.31581 13.6842 6.31581 14.3158 6.70538 14.7054C7.09466 15.0947 7.72569 15.095 8.11538 14.7062L12 10.83L15.8846 14.7062C16.2743 15.095 16.9053 15.0947 17.2946 14.7054C17.6842 14.3158 17.6842 13.6842 17.2946 13.2946L12.7071 8.70711Z"/></svg>',
            
            'show-more': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.2946 9.2947C16.9053 8.90543 16.2743 8.90509 15.8846 9.29393L12 13.1701L8.11538 9.29393C7.72569 8.90509 7.09466 8.90543 6.70538 9.2947C6.31581 9.68428 6.31581 10.3159 6.70538 10.7055L11.2929 15.293C11.6834 15.6835 12.3166 15.6835 12.7071 15.293L17.2946 10.7055C17.6842 10.3159 17.6842 9.68428 17.2946 9.2947Z"/></svg>',
            
            'show': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 17c-1.705 0-3.217-.542-4.539-1.346-1.292-.785-2.398-1.816-3.332-2.829a.475.475 0 01.003-.653c1.03-1.116 2.387-2.257 3.884-3.059C9.276 8.44 10.639 8 11.999 8c1.363 0 2.726.44 3.987 1.113 1.494.805 2.854 1.945 3.884 3.062a.475.475 0 010 .653c-.934 1.01-2.04 2.044-3.33 2.826C15.218 16.458 13.706 17 12 17zm0-7.099c1.531 0 2.774 1.163 2.774 2.599 0 1.436-1.243 2.601-2.775 2.601-1.532 0-2.772-1.165-2.772-2.601s1.24-2.599 2.772-2.599zM8.04 14.82C9.2 15.525 10.52 16 12 16c1.479 0 2.798-.476 3.96-1.181 1.056-.643 1.987-1.475 2.798-2.317-.913-.933-2.057-1.855-3.303-2.522-1.11-.594-2.294-.98-3.456-.98-1.158 0-2.343.386-3.452.98-1.246.667-2.39 1.589-3.303 2.522.808.842 1.742 1.674 2.795 2.317z"/></svg>',
            
            'sort': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.85356 3.89668L6.10356 2.14668C5.90856 1.95143 5.59156 1.95143 5.39656 2.14668L3.64656 3.89668C3.45131 4.09193 3.45131 4.40843 3.64656 4.60368C3.84156 4.79893 4.15856 4.79893 4.35356 4.60368L5.25006 3.70718V13.5002C5.25006 13.7762 5.47406 14.0002 5.75006 14.0002C6.02606 14.0002 6.25006 13.7762 6.25006 13.5002V3.70718L7.14656 4.60368C7.24406 4.70143 7.37206 4.75018 7.50006 4.75018C7.62806 4.75018 7.75606 4.70143 7.85356 4.60368C8.04881 4.40843 8.04881 4.09193 7.85356 3.89668Z"/><path d="M12.3536 11.3965C12.1583 11.2013 11.8418 11.2013 11.6466 11.3965L10.7501 12.293V2.5C10.7501 2.224 10.5261 2 10.2501 2C9.97406 2 9.75006 2.224 9.75006 2.5V12.293L8.85356 11.3965C8.65831 11.2013 8.34156 11.2013 8.14656 11.3965C7.95131 11.5917 7.95131 11.9083 8.14656 12.1035L9.89656 13.8535C9.99431 13.9513 10.1221 14 10.2501 14C10.3781 14 10.5058 13.9513 10.6036 13.8535L12.3536 12.1035C12.5488 11.9083 12.5488 11.5917 12.3536 11.3965Z"/></svg>',
            
            'user-group': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V18C1 18.5523 1.44772 19 2 19H14C14.5523 19 15 18.5523 15 18V16.5C15 14.17 10.33 13 8 13ZM16 13C15.6651 13 15.534 13.4405 15.7705 13.6776C16.5203 14.4293 17 15.361 17 16.5V18C17 18.5523 17.4477 19 18 19H22C22.5523 19 23 18.5523 23 18V16.5C23 14.17 18.33 13 16 13Z"/></svg>',
            
            'view-grid': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="4" width="7" height="7" rx="1"/><rect x="4" y="13" width="7" height="7" rx="1"/><rect x="13" y="4" width="7" height="7" rx="1"/><rect x="13" y="13" width="7" height="7" rx="1"/></svg>',
            
            'view-rows': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="4" width="14" height="4" rx="1"/><rect x="6" y="10" width="14" height="4" rx="1"/><rect x="4" y="16" width="14" height="4" rx="1"/></svg>',
            
            'view-small-grid': '<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 16a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1v-2a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2zm0-6a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2zm-6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2zm-6 0a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1v-2a1 1 0 011-1h2zm0-6a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V5a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V5a1 1 0 011-1h2z"/></svg>'
    });

    }

    if (typeof window !== 'undefined' && window.UIkit) {
        window.UIkit.use(plugin);
    }

    return plugin;

})));
