$(function () {
    jQuery.validator.addClassRules("field-required", {
        required: true,
        normalizer: function (value) {
            return $.trim(value);
        }
    });

    $('#isAccept').change(function () {
        var $this = $('#submit');
        if ($this.attr('disabled')) {
            $this.removeAttr('disabled');
        }
        else {
            $this.attr('disabled', 'disabled');
        }
    });

    $('.show-password-button').click(function () {
        var $iconButton = $(this);
        var $input = $('.password-input');
        if ($input.prop('type') == 'password') {
            $iconButton.attr('uk-icon', 'hide');
            $input.prop('type', 'text');
        }
        else {
            $iconButton.attr('uk-icon', 'show');
            $input.prop('type', 'password');
        }        
    });

    $('.remember-me').click(function () {
        var $checkbox = $(this);
        const current = $checkbox.val().toLowerCase();

        if (current == "true") {
            $checkbox.val("false");
        } else {
            $checkbox.val("true");
        }
    });
});
